import { Link } from "gatsby"
import React from "react"

const LinkStyle = {
    color: `white`,
    textDecoration: `none`,
};
const arrowStyle = {
    color: `darkgray`,
};
const Brand = ({ brandName }) => (
    <div> 
        <Link to="/" style={ LinkStyle } >
            {/* { brandName } */}
            <span style={arrowStyle}>{"<"}</span> 
            {"akinul"}
            <span style={arrowStyle}>{"/>"}</span>
        </Link>
    </div>
)

export default Brand