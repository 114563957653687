import PropTypes from "prop-types"
import React from "react"

import Navigation from "./navigation"
import Brand from "./brand";

import "../styles/header.css"

const Header = ({ siteTitle }) => (
  <header className="common-width-style">
    <div>
      <div id="headerBrand">
        <Brand brandName={ siteTitle } />
      </div>
      <div id="headerNavigation">
        <Navigation />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
