import { Link } from "gatsby"
import React from "react"

import "../styles/navigation.css"

const Navigation = () => (
    <div>
        <Link to="/article/" className="navLink" >Article</Link>
        <span className="navDivider">&nbsp;|&nbsp;</span>
        <Link to="/category/" className="navLink" >Category</Link>
        {/* <span className="navDivider">&nbsp;|&nbsp;</span> */}
        {/* <Link to="/about/" className="navLink" >About</Link> */}
    </div>
)

export default Navigation